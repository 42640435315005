var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{staticClass:"warehouse-row d-flex flex-column flex-sm-row",attrs:{"to":{
    name: 'WarehouseDetail',
    params: {
      warehouseSlug: _vm.warehouse.slug
    }
  }}},[_c('div',{staticClass:"wh-content"},[_c('v-card-title',{staticClass:"pb-1 px-2"},[_vm._v(" "+_vm._s(_vm.warehouseName)+" ")]),_c('v-card-text',{staticClass:"text-body-2 px-2"},[_vm._v(" "+_vm._s(_vm.$t( "navbar.address." + _vm.warehouse.address.addressTypeId + ".list", _vm.warehouse.address ))+" ")]),_c('v-card-actions',[_c('router-link',{staticClass:"font-weight-bold text-decoration-none primary--text",attrs:{"aria-label":`Punto vendita - ${_vm.warehouse.slug}`,"title":`Punto vendita -  ${_vm.warehouse.slug}`,"to":{
          name: 'WarehouseDetail',
          params: {
            warehouseSlug: _vm.warehouse.slug
          }
        }}},[_vm._v(" "+_vm._s(_vm.$t("storeLocator.goToDetail"))+" "),_c('v-icon',{attrs:{"x-small":"","color":"primary"}},[_vm._v("$chevronRight")])],1)],1)],1),_c('v-spacer'),(_vm.warehouse.warehouseClass)?_c('div',{staticClass:"warehouse-classes d-flex align-center"},[_vm._l((_vm.warehouse.warehouseClass),function(wClass,index){return [(wClass.iconSource && wClass.iconSource !== '')?_c('img',{key:index,staticClass:"warehouse-class-img ml-4",attrs:{"src":wClass.iconSource,"alt":wClass.name}}):_vm._e()]})],2):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }